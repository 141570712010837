// @import "functions";
// @import "variables";

$theme-colors: (
  
    primary: #3AB795 ,
  
    sem2: #FD8B6D ,
  
    sem1: #f7b733 
  
  // primary: #007bff,
  // secondary: #6c757d

);


  $primary: #3AB795;

  $sem2: #FD8B6D;

  $sem1: #f7b733;


@import 'bootstrap';

@mixin nav-color-variant($parent, $color, $theme) {
  .nav-pills .nav-link.active#{$parent} {
    background-color: $color !important;
  }
  .nav-pills .nav-link#{$parent}:not(.active) {
    @extend .text-#{$theme};
  }
}

@each $color, $value in $theme-colors {
  @include nav-color-variant(".nav-#{$color}", $value, $color);
}

.nav-vertical {
  display:block;
  align-items: normal;
}

.sticky-top {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {

      }

      @include media-breakpoint-up($next) {
        @supports (position: sticky) {
          position: sticky;
          top: 0;
          z-index: $zindex-sticky;
        }
      }
    }
  }
}
